<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div class="row">
				<div class="col-auto ml-auto d-flex">
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent="setupCourrierType(contract_id)">
			            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('courrier_type.generation') }}
			        </a>
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent="signature" v-if="avenant.avenant_status < 2">
			            <i><font-awesome-icon :icon="['far', 'signature']" /></i> {{ $t('monte.valider') }}
			        </a>
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent="signature" v-if="avenant.avenant_status == 2">
			            <i><font-awesome-icon :icon="['far', 'signature']" /></i> {{ $t('monte.signer') }}
			        </a>
					
					<a href="" v-if="contract.contract_num != null" class="btn ml-3 btn-secondary" @click.prevent="setupContractAValider(contract_id, form.jument.horse_id, processing)">
						<i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.etat_facturation') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
					</a>

					<a href="" v-if="avenant.avenant_status == 3 || avenant.avenant_status == 2" class="btn ml-3 btn-secondary" @click.prevent="addAvenant">
			            <i><font-awesome-icon :icon="['far', 'plus']" /></i> {{ $t('monte.creer_avenant') }}
			        </a>
			        <a href="" v-if="has_many_avenant" class="btn ml-3 btn-secondary" @click.prevent="setupViewHistorique(contract_id)">
			            <i><font-awesome-icon :icon="['far', 'history']" /></i> {{ $t('monte.view_history') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
			        </a>


					<b-dropdown
						lazy
						right
						class="ml-3"
					>
						<template v-slot:button-content>
							<font-awesome-icon :icon="['far', 'cogs']" />
							&nbsp;<span class="d-none d-lg-inline">{{ $t('action.actions') }}</span>
						</template>
						<b-dropdown-item @click.prevent="callPrintContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'file-pdf']" v-if="spinning_print == false" />
							<font-awesome-icon :icon="['far', 'spinner']" :pulse="true" v-else />
							{{ $t('global.imprimer') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="avenant.avenant_status == 3 || avenant.avenant_status == 2" @click.prevent="callCancelContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'times']" />
							{{ $t('action.annuler') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="avenant.avenant_status == 3 || avenant.avenant_status == 2" @click.prevent="callUnsignContracts(avenant.avenant_id)">
							<font-awesome-icon :icon="['far', 'times']" />
							{{ $t('action.devalider_designer') }}
						</b-dropdown-item>
						<b-dropdown-item @click.prevent="callSendContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'paper-plane']" v-if="spinning_send == false" />
							<font-awesome-icon :icon="['far', 'spinner']" :pulse="true" v-else />
							{{ $t('action.envoyer') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="avenant.avenant_status == 4" @click.prevent="callUndoCancelContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'times']" />
							{{ $t('action.desannuler') }}
						</b-dropdown-item>
					</b-dropdown>

					<b-dropdown
						lazy
						right
						v-if="avenant.avenant_status == 3 || avenant.avenant_status == 2"
						class="ml-3"
					>
						<template v-slot:button-content>
							<font-awesome-icon :icon="['far', 'horse']" />
							&nbsp;<span class="d-none d-lg-inline">{{ $t('monte.actes') }}</span>
						</template>
						<b-dropdown-item @click.prevent="openPoulinage">{{ $t('monte.ajouter_poulinage') }}</b-dropdown-item>
						<b-dropdown-item @click.prevent="openDG">{{ $t('monte.ajouter_dg') }}</b-dropdown-item>
						<b-dropdown-item @click.prevent="openSaillie">{{ $t('monte.ajouter_saillie') }}</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
			<form @submit.prevent="checkForm">
				<div class="row">
					<div class="col-xl-6">
						<div class="row mt-3" v-if="this.contract.contract_num">
							<label for="contract_num" class="col-sm-4 col-form-label">{{ $t('monte.numero_contrat') }}</label>
							<div class="col-sm-8"><input type="text" class="form-control" readonly v-model="this.contract.contract_num"></div>
						</div>
						<div class="row mt-3">
							<label for="season" class="col-sm-4 col-form-label">
								{{ $t('tiers.tiers') }} *
								<span class="float-right" v-if="form.tiers.tiers_id">
									<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: form.tiers.tiers_id }}">
										<font-awesome-icon :icon="['fal', 'link']" />
									</router-link>
								</span>
							</label>
							<div class="col-sm-8">
								<!-- <e-select
									:disabled="isDisabled"
									v-model="form.tiers"
									track-by="tiers_id"
									label="tiers_formatted"
									:placeholder="labelTitleTiers"
									:selectedLabel="selectedLabelTiers"
									:options="tiers"
									:allow-empty="false"
									:show-labels="false"
									:loading="loadingTiers"
									:class="{ 'is-invalid': errors && errors.indexOf('tiers') > -1 }"
								></e-select> -->
								<SearchTiers 
									:tiers_selected.sync="form.tiers"
									:preselected="form.tiers"
								/>
							</div>
						</div>

						<template v-if="Object.keys(form.tiers).length > 0 && contract.contract_id">
							<div class="row mt-3">
								<label for="season" class="col-sm-4 col-form-label">
									{{ $t('monte.jument') }}
									<span class="float-right" v-if="form.tiers.tiers_id">
										<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: form.jument.horse_id }}">
											<font-awesome-icon :icon="['fal', 'link']" />
										</router-link>
									</span>
								</label>
								<div class="col-sm-8">
									<!-- <e-select
										v-model="form.jument"
										track-by="horse_id"
										label="horse_nom"
										:placeholder="labelTitleJument"
										:selectedLabel="selectedLabelJument"
										:options="juments"
										:allow-empty="false"
										:show-labels="false"
										:loading="loadingJument"
										:disabled="isDisabled"
										:class="{ 'is-invalid': errors && errors.indexOf('jument') > -1 }"
									></e-select> -->
									<div class="row">
										<div class="col-sm-12 col-lg-6">
											<SearchJument
											ref="searchJument"
											:horse_selected.sync="form.jument"
											:preselected="form.jument"
											:addHorseAllowed="true"
											:tier="this.form.tier"
											@addHorseVal="openAddHorseModal"
											@stopFocus="stopFocus()"
											/>
										</div>
										<div class="col-sm-12 col-lg-6" v-if="jumentInfo">
											<span>
												{{ $t('horse.numero_sire') }}: {{jumentInfo.sire}} <br>
												{{ $t('horse.pere') }}: {{jumentInfo.pere}} <br>
											</span>
										</div>
									</div>
									<div v-if="couple_contract" class="mt-1 mb-0 alert alert-warning">{{ $t('monte.couple_alreay_contract') }}</div>
									<div v-if="!couple_contract && mare_contract" class="mt-1 mb-0 alert alert-warning">{{ $t('monte.mare_alreay_contract') }}</div>
								</div>
								<div v-if="jument && jument.horse_mort === 1">
									<b-alert show variant="warning" class="mt-2">
										<font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
										{{ $t('horse.Jument_morte') }}
									</b-alert>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col-sm-12">
									<b-form-checkbox
									id="avenant_revue"
									v-model="avenant.avenant_revue_bool"
									:value="1"
									:unchecked-value="0"
									:disabled="isDisabled"
									>
									{{ $t("monte.avenant_revue") }}
									</b-form-checkbox>
								</div>
							</div>
							<div v-if="form.jument" class="row mt-3">
								<label class="col-sm-4 col-form-label">{{ $t('gynecologie.mare_status') }}</label>
								<div class="col-sm-8">
									<e-select
										v-model="mare_status_selected"
										id="marestatus_id"
										track-by="marestatus_id"
										:placeholder="$t('gynecologie.selectionnez_etat')"
										:selectedLabel="$t('global.selected_label')"
										:options="mare_status"
										:searchable="false"
										:allow-empty="false"
										:show-labels="false"
									>
										<template slot="option" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
										<template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>

							<Saison
								ref="Saison"
								:all_season="all_season"
								:season="contract.config.season"
								v-model="season"
								:disabled="isDisabled"
							>
							</Saison>

							<Etalon
								ref="Etalon"
								:stallion_id="contract.config.contractconfig_horse"
								v-model="stallion"
								:contractconfig_id.sync="contractconfig_id"
								:contract_config.sync="contract_config"
								:season.sync="season"
								:disabled="isDisabled"
							>
							</Etalon>

							<Modele
								ref="Modele"
								:contractconfig_id="contractconfig_id"
								:avenant_model.sync="avenant.avenant_model"
								v-model="modele"
							></Modele>
							<!-- :disabled="isDisabled" -->

							<TypeMonte
								ref="TypeMonte"
								:contractconfig_id="contractconfig_id"
								:avenant_typemonte="avenant.avenant_typemonte"
								v-model="type_monte"
								:disabled="isDisabled"
							></TypeMonte>

							<div class="row mt-3">
								<div class="col-sm-4 col-form-label"></div>
								<div class="col-sm-8">
									<b-form-checkbox
										v-model="form.avenant_transfert"
										:disabled="isDisabled"
										:value="1"
										:unchecked-value="0"
									>
										{{ $t('monte.transfert') }}
									</b-form-checkbox>
								</div>
							</div>

							<div class="row mt-3" v-if="form.avenant_transfert && form.jument.horse_id">
								<label for="porteuse" class="col-sm-4 col-form-label">
									{{ $t('monte.porteuse') }}
									<span class="float-right" v-if="form.porteuse">
										<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: form.porteuse.horse_id }}">
											<font-awesome-icon :icon="['fal', 'link']" />
										</router-link>
									</span>
								</label>
								<div class="col-sm-7">
									<!-- <e-select
										v-model="form.porteuse"
										track-by="horse_id"
										label="horse_nom"
										:placeholder="labelTitlePorteuse"
										:selectedLabel="selectedLabelPorteuse"
										:options="juments_porteuse"
										:allow-empty="false"
										:show-labels="false"
										:loading="loadingJument"
										:class="{ 'is-invalid': errors && errors.indexOf('jument') > -1 }"
									></e-select> -->
									<SearchJument
										:horse_selected.sync="form.porteuse"
										:preselected="form.porteuse"
									/>
								</div>
								<div class="col-sm-1">
				                	<button class="btn btn-secondary" @click="addPorteuse"><font-awesome-icon :icon="['far', 'plus']" /></button>
				                </div>
							</div>

							<div v-if="most_used_cmep" class="row mt-3">
								<i class="btn btn-link my-auto" @click="loadCmep()">{{ $t("tiers.cmep_want_to") }} {{ most_used_cmep.tiers_rs }} ?</i>
							</div>
							<ChangementCMEP
								ref="changementCMEP"
								:contract_id="contract_id"
								:avenant.sync="avenant"
								:tiers_tab.sync="all_tiers"
								:cmep.sync="form.cmep"
								:disabled="isDisabled"
							>
							</ChangementCMEP>
							<div class="row mt-3">
								<label class="col-sm-4 col-form-label" for="courtier">{{ $t('monte.courtier') }}</label>
								<div class="col-sm-8">
									<!-- <e-select
										v-model="form.courtier"
										track-by="tiers_id"
										label="tiers_rs"
										:placeholder="labelTitleCourtier"
										:selectedLabel="selectedLabelCourtier"
										:options="tiers_courtier"
										:allow-empty="true"
										:show-labels="false"
										:loading="loadingTiers"
										:class="{ 'is-invalid': errors && errors.indexOf('courtier') > -1 }"
									></e-select> -->

									<SearchTiers 
										:tiers_selected.sync="form.courtier"
										:preselected="form.courtier"
									/>
								</div>
							</div>
							<div class="row mt-3" v-if="avenant.avenant_type_contrat != 'etalonnier'">
								<label for="saillie" class="col-sm-4 col-form-label">{{ $t('monte.num_parts_saillie') }}</label>
								<div class="col-sm-8">
									<e-select
										v-model="saillie"
										id="saillie"
										track-by="saillie_id"
										:placeholder="$t('monte.numero_saillie')"
										:selectedLabel="$t('global.selected_label')"
										:options="syndic_saillie_formatted"
										:allow-empty="false"
										:show-labels="false"
										:loading="load_syndic"
										:class="{ 'is-invalid': errors && errors.indexOf('saillie') > -1 }"
									>
										<template slot="option" slot-scope="{ option }">{{ option.value }}</template>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.value }}</template>
									</e-select>
									<b-form-checkbox
										v-if="saillie"
										id="free_saillie"
										v-model="free_saillie"
										:value="1"
									>
										{{ $t("monte.free_saillie") }}
									</b-form-checkbox>
								</div>
							</div>
							<template v-if="avenant.avenant_status == 1 || avenant.avenant_status == 2 || avenant.avenant_status == 3">
								<div v-if="avenant.avenant_date_signature" class="row mt-3">
									<label for="date_signature" class="col-sm-4 col-form-label">{{ $t("monte.signature_date") }}</label>
									<div class="col-sm-8">
										<input class="form-control" v-model="date_signature" id="date_signature" disabled />
									</div>
								</div>
								<div class="row mt-3">
									<label for="type_contrat" class="col-sm-4 col-form-label">{{ $t("monte.type_contrat") }}</label>
									<div class="col-sm-8">
										<input type="text" class="form-control" :value="avenant.avenant_type_contrat?$t('monte.'+avenant.avenant_type_contrat):''" id="type_contrat" disabled />
									</div>
								</div>


								<div class="row mt-3">
									<label class="col-sm-4 col-form-label" for="numero_dps">{{ $t("monte.numero_dps") }}</label>
									<div class="col-sm-8">
										<input type="text" class="form-control" v-model="num_dps" id="numero_dps" >
									</div>
								</div>
								<div class="row mt-3">
									<label class="col-sm-4 col-form-label" for="paillette">{{ $t("monte.nombre_paillettes") }}</label>
									<div class="col-sm-8">
										<input type="text" class="form-control" v-model="form.paillette" id="paillette" >
									</div>
								</div>
								<div class="row mt-3">
									<label class="col-sm-4 col-form-label" for="contract_created">{{ $t("monte.contract_created") }}</label>
									<div class="col-sm-8">
										<e-datepicker v-model="form.contract_created" id="contract_created" ></e-datepicker>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-sm-12">
										<b-form-checkbox
										id="attestation_saillie"
										v-model="avenant.avenant_as_bool"
										:value="1"
										:unchecked-value="0"
										>
										{{ $t("monte.attestation_saillie") }}
										</b-form-checkbox>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-sm-4">
										<b-form-checkbox
										id="declaration_naissance"
										v-model="avenant.avenant_dn_bool"
										:value="1"
										:unchecked-value="0"
										>
										{{ $t("monte.declaration_naissance") }}
										</b-form-checkbox>
									</div>
									<div class="col-sm-8">
										<e-datepicker v-if="avenant.avenant_dn_bool" v-model="avenant.avenant_dn" id="avenant_dn" ></e-datepicker>
									</div>
								</div>
							</template>

						</template>
					</div>
					<div class="col-xl-6 mt-3">
						<template v-if="Object.keys(form.tiers).length > 0 || contract.contract_id">

								<div class="form-group">
									<label for="season">{{ $t('monte.avenant_justification') }}</label>
									<e-editor-froala
										identifier="avenant_justification"
										:placeholder="getTrad('monte.avenant_justification')"
										:content="justification"
										@change="updateEditorJustification"
										:disabled="isDisabled"
									></e-editor-froala>
								</div>
								<div class="form-group">
									<label for="season">{{ $t('monte.avenant_comment') }}</label>

									<e-editor-froala
										identifier="avenant_comment"
										:placeholder="getTrad('monte.avenant_comment')"
										:content="comment"
										@change="updateEditorComment"
									></e-editor-froala>
								</div>
								<div class="form-group">
									<label for="season">{{ $t('monte.contract_note') }}</label>
									<e-editor-froala
										identifier="contract_note"
										:placeholder="getTrad('monte.contract_note')"
										:content="note"
										@change="updateEditorNote"
									></e-editor-froala>
								</div>
						</template>
					</div>
				</div>
					<template v-if="Object.keys(form.tiers).length > 0">
						<div class="row">
							<h2 class="my-3 col-12">Facturation</h2>
						</div>
						<div class="row" style="margin-bottom:-65px">
							<div class="col-sm-4 col-form-label"></div>
							<div class="col-sm-auto">
								<b-form-checkbox
									id="avenant_free"
									v-model="avenant.avenant_free"
									:value="1"
									:unchecked-value="0"
									:disabled="isDisabled"
								>
									{{ $t("monte.contrat_gratuit") }}
								</b-form-checkbox>
							</div>
						</div>
						<TableAvenantArticle
							ref="TableAvenantArticle"
							:articles="contract.articles"
							:tiers_tab="all_tiers"
							:tiers="form.tiers"
							:typemonte="type_monte"
							:contractconfig_id="contractconfig_id"
							:avenant="avenant"
							:avenant_article.sync="avenant_article"
							:disabled="isDisabled"
							:created_horse_id.sync="form.jument.horse_id"
							:display_action_button="true"
							:free="avenant.avenant_free"
							:contract_id="contract_id"
							:is_ready.sync="isTableAvenantArticleReady"
						></TableAvenantArticle>

						<div class="row">
							<div class="col-12" v-if="form_message !== ''">
								<ErrorAlert :messageI18n="form_message" />
							</div>

							<div class="col-12">
								<div class="form-group text-center">
									<button
										class="btn btn-primary rounded-pill"
										@click='saveInfo'
										:disabled="!isTableAvenantArticleReady"
									>
										<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
										<font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" />
										{{$t("global.enregistrer")}}
									</button>

									<button
										v-if="avenant.avenant_status < 2"
										class="btn btn-secondary rounded-pill ml-2"
										@click='saveInfoValide'
										:disabled="!isTableAvenantArticleReady"
									>
										<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
										<font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" />
										{{$t("global.enregistrer_valider")}}
									</button>
								</div>
							</div>
						</div>
					</template>



				<div class="row mt-3">
					<div class="col-12">
						<div class="form-group" v-if="canTakeResaMonte">
							<div class="">
								<ReservationMonte
									:contract_id="contract_id"
									:available_tiers="all_tiers"
									:available_mares="juments"
									:available_centre_stockage="available_centre_stockage"
									:season="season"
									:stallion="stallion"
								/>
							</div>
						</div>
					</div>
				</div>


			</form>

			<b-modal ref="modalAddHorse" hide-footer size="lg">
                <template v-slot:modal-title>
                        {{ $t("tiers.ajouter_tiers_horse") }}
                </template>

                <HorseAjout 
                    :add-or-edit-ready.sync="addHorseReady"
                    :disable-button.sync="disableButton"
                    ref="tiersHorseAjout"
                    :tiershorse_id="null"
                    :tiers="form.tiers"
                    :tiers_horse="[]"
					:horseToAddName.sync="horseToAddName"
					@horse_id="assignHorse"
					:horseSexeMandatory = horseSexeMandatory
                />

                <b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkFormHorse" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
            </b-modal>

			<ModalAddAvenant
				ref="modalAddAvenant"
				:stallion_id="contract.config.contractconfig_horse"
				:season_id="contract.config.contractconfig_season"
				:season_choosen="season"
				:contractconfig_id="contractconfig_id"
				:avenant_typemonte="avenant.avenant_typemonte"
				:tiers_tab="all_tiers"
				:tiers="form.tiers"
				:typemonte="type_monte"
				:avenant="avenant"
				:horse="form.jument"
				:is_cancel="isAvenantCancel"
				:all_season="all_season"
				:syndic_saillie="syndic_saillie"
				@avenant_saved="init_component(false)"
			>
			</ModalAddAvenant>

			<ModalSignature
				v-if="contract.config && contract.config.contractconfig_id && season.season_id"
				ref="modalContractSignature"
				:avenant="avenant"
				:contract_id="contract_id"
				:tiers_tab="all_tiers"
				:tiers="form.tiers"
				:type_monte="type_monte"
				:contractconfig_id="contractconfig_id"
				:contract_config="contract.config"
				:stallion="stallion"
				:season_id="season.season_id"
				:articles="contract.articles"
				:syndic_saillie="syndic_saillie"
				:load_syndic="load_syndic"
				@contract_signed="contractSigned"
			>
			</ModalSignature>

			<ModalCourrier
				ref="modelCourrier"
				:tiers="form.tiers"
			>
			</ModalCourrier>

			<ModalSendContract ref="modal_send_contract"/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import GynecologieMixin from "@/mixins/Gynecologie.js"
	import ModelMixin from "@/mixins/Model.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	import HorseMixin from "@/mixins/Horse.js"
	import Syndic from "@/mixins/Syndic.js"
	import _isEmpty from 'lodash/isEmpty'
	import _uniq from 'lodash/uniq'
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import HorseAjout from "@/components/Tiers/HorseAjout";

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'

	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import { EventBus } from 'EventBus'

	export default {
		name: "ContractForm",
		props: {
			contract_id: {
				type: Number,
				default: () => ( 0 )
			},
			from: {
				type: String,
				default: () => ( "" )
			},
			stallion_id: {
				type: Number,
				default: () => ( 0 )
			}
		},
		mixins: [Navigation, Tools, ContractMixin, GynecologieMixin, ModelMixin, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin, Shutter, ShutterContract, HorseMixin, Syndic, MonteReservationOrder, MonteShutters],
		data () {
			return {
				horseSexeMandatory: ['F'],
				horse_id: null,
				disableSave:false,
				NewjumentLoaded: false,
				jumentInfo:null,
				form_message: "",
				addOrEditReady: false,
				horseToAddName: null,
				disableButton: true,
				addHorseReady:false,
				isDisabled: true,
				loadingTiers: false,
				loadingStallion: false,
				loadingModele: false,
				loadingTypeMonte: false,
				loadingJument: false,
				isTableAvenantArticleReady: false,
				has_part: false,
				conditions_spe: false,
				table_busy: false,
				saillie: null,
				syndic_saillie: null,
				contractconfig_id: 0,
				processing: false,
				errors: [],
				conditions: [],
				stallions: [],
				juments: [],
				jument: null,
				juments_porteuse: [],
				articles: [],
				tiers: [],
				tiers_courtier: [],
				all_tiers: [],
				entities: [],
				contract_config: {}, //Contract config selectionné en fonction de l'étalon et de la saison
				contract: {}, //Contract
				avenant: {}, //Avenant utilisé
				num_part: null,
				num_dps: null,

				form: {
					tiers:  {},
					season:  {},
					stallion:  {},
					modele:  null,
					type_monte:  null,
					jument: {},
					porteuse: {},
					cmep: null,
					courtier: null,
					avenant_comment: '',
					avenant_justification: '',
					avenant_transfert: 0,
					contract_note: '',
					paillette: 0,
					contract_created: new Date()
				},
				labelTitleTiers: this.getTrad("monte.rechercher_tiers"),
				selectedLabelTiers: this.getTrad("global.selected_label"),
				labelTitleJument: this.getTrad("monte.rechercher_jument"),
				selectedLabelJument: this.getTrad("global.selected_label"),
				labelTitleCmep: this.getTrad("monte.rechercher_cmep"),
				selectedLabelCmep: this.getTrad("global.selected_label"),
				labelTitleCourtier: this.getTrad("monte.rechercher_courtier"),
				selectedLabelCourtier: this.getTrad("global.selected_label"),
				labelTitlePorteuse: this.getTrad("monte.rechercher_porteuse"),
				selectedLabelPorteuse: this.getTrad("global.selected_label"),
				extern_slot_columns: [
				    'avenantarticles_ht',
				    'tiers.tiers_rs',
				    'author.tiers_rs',
				    'avenantarticles_invoiceable_date',
				],

				/*NEW */
				season: {},
				stallion: {},
				modele: {},
				type_monte: {},
				avenant_article: {},
				show_bons: false,

				spinning_print: false,
				spinning_send: false,
				has_many_avenant: false,
				note: null,
				comment: null,
				justification: null,
				isAvenantCancel: false,

				mare_status_selected: null,
				mare_status: [],
				season_mare: null,
				mare_contract: false,
				couple_contract: false,
				available_centre_stockage: [],
				all_season: [],
				events_tab: {
					'ContractForm::ReloadContract': this.initContract
				},
				porteuse_created: false,
				syndic_horse: {
					stallion: null,
					season: null
				},
				free_saillie: 0,
				load_syndic: false,
				most_used_cmep: null
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component(validation = false) {
				this.addOrEditReady = false


				this.syndic_horse = {
					stallion: null,
					season: null
				}

				await this.initContract()

				await this.initForm()

				this.addOrEditReady = true

				this.initTiers()
				this.initSeason()
				this.initJuments()
				this.initMareStatus()

				if(validation === true) {
					this.$nextTick(() => {
						this.signature()
	                })
				}
			},
			async initContract() {
				this.contract = await this.getContract(this.contract_id)
				this.contractconfig_id = this.contract.contract_contractconfig || 0

				let tries = 5

				if(window.navigator.onLine && this.contract.contract_id > 0 && this.contractconfig_id !== 0) {
					while(!this.contract.contract_num && tries != 0) {
						await this.$sync.force(true, true)
						this.contract = await this.getContract(this.contract_id)
						tries--
					}
				}

				this.avenant = this.contract.avenant
				if(this.avenant) {
					this.num_dps = this.avenant.avenant_dps
				}
			},
			async initMareStatus() {
				this.mare_status = await this.loadMareStatus()
			},
			async initTiers() {
				this.loadingTiers = true
				this.tiers = await this.loadTiers()
				this.tiers_courtier = this.tiers
				this.all_tiers = this.tiers
				this.loadingTiers = false

				let preselection_tiers = []
				if(this.contract.contract_tiers[0] != undefined)
				{
					this.form.tiers = this.contract.contract_tiers[0].tiers
				}
				else
				{
					if(this.avenant.avenant_horse != null)
					{
						//Allez chercher dans tiers_horse le 1er que je trouve
						preselection_tiers = await this.loadHorseTiers(this.avenant.avenant_horse)
						if(preselection_tiers.length > 0)
						{
							this.form.tiers = preselection_tiers[0]
						}
					}
				}

				//Je vais restreindre mes tiers si je viens de la fiche jument
				if(this.from == "mareList")
				{
					this.tiers = this.tiers.filter((el) => {
					  return preselection_tiers.some((f) => {
					    return f.tiers_id === el.tiers_id ;
					  });
					});

					this.juments = this.juments.filter((el) => {
						return el.horse_id == this.avenant.avenant_horse
					});
				}
			},
			async initJuments() {
				this.loadingJument = true
				this.juments = await this.loadJuments()
				if (this.juments[0] && this.form.jument.length > 0){
					this.jument = await this.getHorseById(this.juments[0].horse_id)
					if(this.jument.horse_mort === 1){
						this.disableSave = true
					}
					else{
						this.disableSave = false
					}
					if(this.form.jument.length > 0){
						this.jumentInfo = {"sire": this.jument.horse_sire + this.jument.horse_cle, "pere": this.jument.pedigree.pere}
					}
				}
				this.loadingJument = false
			},
			async initForm() {
				//Si l'avenant est encore ou a été remis en brouillon/dévalidé
				if(this.avenant.avenant_status == 1)
				{
					this.isDisabled = false
				}
				else {
					this.isDisabled = true
				}

				if(this.contract.courtier != undefined)
				{
					this.form.courtier = this.contract.courtier
				}

				this.form.contract_note = this.contract.contract_note
				this.form.contract_created = this.contract.contract_created
				this.form.avenant_justification = this.avenant.avenant_justification
				this.form.avenant_comment = this.avenant.avenant_comment
				this.form.avenant_transfert = this.avenant.avenant_transfert
				this.form.avenant_free = this.avenant.avenant_free
				this.form.paillette = this.contract.contract_paillette

				this.note = this.contract.contract_note
				this.comment = this.avenant.avenant_comment
				this.justification = this.avenant.avenant_justification

				if(this.contract.contract_tiers.length > 0 && this.contract.contract_tiers[0].tiers)
				{
					this.tiers = [this.contract.contract_tiers[0].tiers]
					this.form.tiers = this.contract.contract_tiers[0].tiers
				}

				if(this.avenant.horse != undefined)
				{
					this.form.jument = this.avenant.horse
					this.juments = [this.form.jument]
				}

				if(this.avenant.avenant_porteuse != undefined)
				{
					this.form.porteuse = this.avenant.porteuse
					this.juments_porteuse = [this.form.jument]
				}

				if(this.avenant.cmep != undefined)
				{
					this.form.cmep = this.avenant.cmep
				}

				let nb_avenants = await this.getAllAvenantFromContract(this.contract_id)
				if(nb_avenants.length > 1)
				{
					this.has_many_avenant = true
				}

				if(!this.contract_id && this.stallion_id) {
					this.contract = {config: { contractconfig_horse: this.stallion_id }}
				}

				if(this.contract_id && this.$route.params.stallion_id) {
					this.contract.config.contractconfig_horse = parseInt(this.$route.params.stallion_id)
				}

				if(this.contract_id && this.stallion_id) {
					this.contract.config.contractconfig_horse = this.stallion_id
				}
			},
			async initSeason() {
				this.all_season = await this.loadSeasons(true)
			},
			async SetInfoJument(val){
				if (this.NewjumentLoaded) {
					this.NewjumentLoaded = false
					return
				}
				if(val.horse_id){
					this.jument = await this.getHorseById(val.horse_id)
					if(this.jument.horse_mort === 1){
						this.disableSave = true
					} else {
						this.disableSave = false
					}
					this.form.jument = this.jument
					this.jumentInfo = {"sire": this.jument.horse_sire + this.jument.horse_cle, "pere": this.jument.pedigree.pere}
					this.NewjumentLoaded = true
				}
			},
			async saveInfo(validation = false){
				if (this.disableSave){
					this.failureToast("toast.warning_jument_mort")
					return
				}

				if(this.processing == false)
				{
					this.processing = true

					this.errors = []
					this.form_message = ''

					if(this.contractconfig_id == 0)
					{
						this.errors.push("season")
						this.errors.push("stallion")
						this.form_message = "monte.contract_config_not_found"
					}
					else if (!this.type_monte) {
						this.errors.push('type_monte')
						this.form_message = 'monte.type_monte_not_set'
					}
					else if (!this.modele) {
						this.errors.push('modele')
						this.form_message = 'monte.modele_not_set'
					}

					//Je vais regarder qu'on a une seule devise dans les articles à facturer 
					let tab_currency = []
					if(this.avenant_article.length > 0) {
						for (let i = 0; i < this.avenant_article.length; i++) {
							const element = this.avenant_article[i];
							if(element.author.tiers_currency) {
								tab_currency.push(element.author.tiers_currency)
							}
						}
					}
					tab_currency = _uniq(tab_currency)
					if(tab_currency.length > 1) {
						this.errors.push('currency')
						this.form_message = 'monte.multiple_currency'
					}

					if (this.form_message) {
						this.processing = false
						return
					}

					if(!this.form.avenant_transfert) {
						this.form.porteuse = null
					}

					let infos = {
						contract_note: this.form.contract_note,
						contract_created: this.form.contract_created ? this.form.contract_created : new Date(),
						avenant_justification: this.form.avenant_justification,
						avenant_comment: this.form.avenant_comment,
						avenant_transfert: this.form.avenant_transfert,
						avenant_free: this.avenant.avenant_free,
						avenant_dps: this.num_dps,
						avenant_as_bool: this.avenant.avenant_as_bool,
						avenant_dn_bool: this.avenant.avenant_dn_bool,
						avenant_revue_bool: this.avenant.avenant_revue_bool,
						avenant_dn: this.avenant.avenant_dn,
						cmep: this.form.cmep,
						type_monte: this.type_monte,
						modele: this.modele,
						courtier: this.form.courtier,
						jument: this.form.jument,
						porteuse: this.form.porteuse,
						paillette: this.form.paillette
					}

					if(!this.isDisabled) {
						await this.saveTiersContract(this.contract_id, this.form.tiers.tiers_id)
					}

					await this.checkHorseTiers(this.form.tiers.tiers_id, this.form.jument.horse_id)

					await this.saveContract(this.contract.contract_id, infos, this.contractconfig_id, this.avenant.avenant_id, this.isDisabled)
					if(this.contract.contract_id < 0 || this.avenant.avenant_id < 0)
					{
						await this.$sync.force(true, true)
						this.avenant.avenant_id = this.$sync.replaceWithReplicated('contract_avenant', this.avenant.avenant_id)
						this.contract.contract_id = this.$sync.replaceWithReplicated('contract', this.contract.contract_id)
					}

					await this.saveAvenantArticles(this.avenant.avenant_id, this.avenant_article, this.contract.contract_id)

					if(this.saillie != null){
						await this.savePartContract(this.contract.contract_id, this.saillie.saillie_id)
						this.resetSyndic()
					}
					else if(!this.saillie && this.free_saillie) {
						await this.freeSaillieContract(this.contract.contract_id)
						this.resetSyndic()
					}

					let horse_id = this.form.jument.horse_id
					if(!this.season_mare && horse_id && this.season) {
                    	await this.addHorseToSeason(horse_id, this.season.season_id)
						this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)[0]
					}

					// met à jour le season_mare_stallion de la jument sur l'étalon du contrat
					// if(Object.keys(this.form.jument).length > 0 && this.season_mare) {
					// 	await this.editAllSeasonMareStallionDefault(this.season_mare.seasonmare_id, { seasonmarestallion_default: 0 })
					// 	const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id)

					// 	const porteuse = this.form.porteuse ? this.form.porteuse.horse_id : null
					// 	let sm_porteuse = null

					// 	if(porteuse) {
					// 		sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
					// 		if(!sm_porteuse) {
					// 			await this.addHorseToSeason(porteuse, this.season.season_id)
					// 			sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
					// 		}
					// 		sm_porteuse = sm_porteuse[0].seasonmare_id
					// 	}

					// 	if(!seasonstallion) {
					// 		await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1, sm_porteuse)
					// 	} else {
					// 		await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1, seasonmarestallion_porteuse: sm_porteuse })
					// 	}
					// }

					await this.saveSeasonMare()
					
					await this.$sync.force(true, true)

              		ContractCleaner.inst().onMutation([this.contract_id], ['articles'])

					this.successToast("toast.info_modif_succes")
					this.processing = false
					EventBus.$emit('PlanningMonte::refresh')

					await this.init_component(validation)
				}
			},
			async saveInfoValide() {
				await this.saveInfo(true)
			},
			addAvenant() {
				this.$refs.modalAddAvenant.openModal()
			},
			signature() {
				this.$refs.modalContractSignature.openModal()
			},
			etatFacturation(){
				this.show_bons = !this.show_bons
			},
			async callPrintContracts(contract_id){
				let id = this.$sync.replaceWithReplicated('contract', contract_id)
				if(this.processing){
					this.failureToast("monte.wait_sync")
					return false
				}

				this.spinning_print = true
				let result = await this.printContract(parseInt(id))
				if(result == null){
					this.failureToast('monte.print_error')
				}
				this.spinning_print = false
			},
			async callSendContracts(contract_id){
				let id = this.$sync.replaceWithReplicated('contract', contract_id)
				if(this.processing){
					this.failureToast("monte.wait_sync")
					return false
				}

				this.spinning_send = true
				this.openModalSendContract([id])
				this.spinning_send = false
			},
			async callCancelContracts(contract_id){
				this.isAvenantCancel = true
				await this.addAvenant()
			},
			async callUndoCancelContracts(contract_id){
				this.addOrEditReady = false

				let infos = {
					contract_note: this.form.contract_note,
					avenant_justification: this.form.avenant_justification,
					avenant_comment: this.form.avenant_comment,
					avenant_transfert: this.form.avenant_transfert,
					avenant_free: this.avenant.avenant_free,
					avenant_dps: this.num_dps,
					avenant_as_bool: this.avenant.avenant_as_bool,
					avenant_dn_bool: this.avenant.avenant_dn_bool,
					avenant_revue_bool: this.avenant.avenant_revue_bool,
					avenant_dn: this.avenant.avenant_dn,
					cmep: this.form.cmep,
					type_monte: this.type_monte,
					modele: this.modele,
					courtier: this.form.courtier,
					jument: this.form.jument,
					porteuse: this.form.porteuse,
					paillette: this.form.paillette
				}

				await this.UndoCancelContract(contract_id, infos)
				this.init_component()
			},
			async callUnsignContracts(avenant_id){
				await this.unsignContract(avenant_id)
				this.init_component()
			},
			updateEditorNote(val){
				this.form.contract_note = val.val
			},
			updateEditorComment(val){
				this.form.avenant_comment = val.val
			},
			updateEditorJustification(val){
				this.form.avenant_justification = val.val
			},
			async selectStatus() {
				let horse_id = this.form.jument.horse_id
				if (!horse_id || !this.season || !this.season.season_id) {
					return
				}

				const season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)
				this.season_mare = season_mare[0]
				if(this.season_mare) {
					this.mare_status_selected = this.mare_status.find(stat => stat.marestatus_id == this.season_mare.seasonmare_status);
				} else {
                    await this.addHorseToSeason(this.form.jument.horse_id, this.season.season_id)
                    this.selectStatus()
				}
			},

			async reloadJument(tiers) {
				const horse_tiers = await this.loadHorse(tiers.tiers_id);
				this.juments = horse_tiers.map(horse => horse.horse).filter(horse => horse != undefined && horse.horse_sexe == "F")
				this.juments_porteuse = this.juments
			},

			async reloadNumDps() {
				if(!this.avenant.avenant_dps && this.form.jument && this.stallion && this.season) {
					if(this.form.jument.horse_id && this.stallion.horse_id && this.season.season_id) {
						this.num_dps = await this.getNumDps(this.form.jument.horse_id, this.stallion.horse_id, this.season.season_id)
					}
				}
			},

			openModelCourrier() {
				this.$refs.modelCourrier.openModal()
			},

			async loadSyndic(force=false) {
				if(this.stallion && this.stallion.horse_id && this.season && this.season.season_id) {
					if((this.syndic_horse.stallion != this.stallion.horse_id) || (this.syndic_horse.season != this.season.season_id) || force) {
						this.syndic_horse.stallion = this.stallion.horse_id
						this.syndic_horse.season = this.season.season_id

						this.load_syndic = true
						this.syndic_saillie = await this.loadHorseSyndicBySeason(this.stallion.horse_id, this.season.season_id, this.contract.avenant.avenant_date_validation)
						this.load_syndic = false
					}
				}
			},

			async checkContract() {
				this.mare_contract = false
				this.couple_contract = false

				if(!this.form.jument.horse_id || !this.season) return false

				const all_contract = await this.loadContracts(this.form.jument.horse_id)
				const contract_season = all_contract.filter((contract) => {
					if(typeof contract.contract !== "object") {
						return false
					}
					return contract.contract.config.contractconfig_season == this.season.season_id
					&& contract.avenant_status != 4
					&& contract.contract.contract_id != this.$sync.replaceWithReplicated('contract', this.contract_id)
				})
				this.mare_contract = contract_season.length > 0

				if(this.stallion) {
					const contract_couple = all_contract.filter((contract) => {
						if(typeof contract.contract !== "object") {
							return false
						}
						return contract.contract.config.contractconfig_season == this.season.season_id
						&& contract.contract.contract_id != this.$sync.replaceWithReplicated('contract', this.contract_id)
						&& contract.contract.config.horse.horse_id == this.stallion.horse_id
					})
					this.couple_contract = contract_couple.length > 0
				}
			},

			async loadTiersCentreStockage() {
				this.available_centre_stockage = await this.loadCentreStockage()
			},

			openPoulinage() {
				let mare = this.avenant.avenant_horse
				if(this.avenant.avenant_porteuse) {
					mare = this.avenant.avenant_porteuse
				}

				this.setupAddPoulinage(mare)
			},

			openDG() {
				let mare = this.avenant.avenant_horse
				if(this.avenant.avenant_porteuse) {
					mare = this.avenant.avenant_porteuse
				}

				this.setupAddDG(mare)
			},

			openSaillie() {
				let mare = this.avenant.avenant_horse
				if(this.avenant.avenant_porteuse) {
					mare = this.avenant.avenant_porteuse
				}

				this.setupAddSaillie(mare)
			},
			async addPorteuse() {
				if(!this.season) return false

				let shutter = this.monteShutters['monte-horse-ajout']
				shutter.props.season_id = this.season.season_id
				shutter.props.unknown = true

				shutter.onOk = async (horse_id) => {
					this.juments_porteuse = await this.getHorseFemelle()
					this.form.porteuse = this.juments_porteuse.find(horse => horse.horse_id == horse_id)
				}

				this.shutterPanel().open(shutter)
			},
			async saveSeasonMare() {
				const horse_id = this.form.jument.horse_id

				if(!this.season.season_id || !horse_id || !this.stallion || !this.stallion.horse_id) return false

				// si j'ai pas de season_mare je le crée
				let season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)
				this.season_mare = season_mare[0]
				if(!this.season_mare) {
                    await this.addHorseToSeason(horse_id, this.season.season_id)
					let season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)
					this.season_mare = season_mare[0]
				}

				if(this.mare_status_selected) {
					await this.saveStatutSanitary(this.season_mare.seasonmare_id, this.mare_status_selected.marestatus_id)
				}

				// je mets tous les sms de la jument en default à 0
				await this.editAllSeasonMareStallionDefault(this.season_mare.seasonmare_id, { seasonmarestallion_default: 0 })

				// si je n'ai pas de porteuse
				if(!this.form.porteuse || !this.form.porteuse.horse_id) {
					// je récupère le sms, si j'en ai un je le passe en default à 1, sinon je le crée 
					const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, true)
					if(!seasonstallion) {
						await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1)
					} else {
						await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1 })
					}
				}
				else {
					const porteuse = this.form.porteuse.horse_id

					// je récupère le season_mare sur la porteuse, sinon je le crée
					let sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
					sm_porteuse = sm_porteuse[0]
					if(!sm_porteuse) {
	                    await this.addHorseToSeason(porteuse, this.season.season_id)
						sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
						sm_porteuse = sm_porteuse[0]
					}

					const seasonstallion = await this.getSeasonMareStallionBySeasonMareStallionAndPorteuse(this.season_mare.seasonmare_id, this.stallion.horse_id, sm_porteuse.seasonmare_id)
					if(!seasonstallion) {
						await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1, sm_porteuse.seasonmare_id)
					} else {
						await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1, seasonmarestallion_porteuse: sm_porteuse.seasonmare_id })
					}
				}
			},

			async contractSigned() {
				this.addOrEditReady = false
				this.$refs.modalContractSignature.hideModal()
				await this.loadSyndic(true)
				this.init_component()
				this.setupContractAValider(this.contract_id, this.form.jument.horse_id, this.processing)
			},

			openModalSendContract(contract_ids) {
				this.$refs.modal_send_contract.openModal(contract_ids)
			},

			resetSyndic() {
				this.saillie = null
				this.free_saillie = 0
				this.syndic_saillie = []
			},

			openAddHorseModal(val){
				this.horseToAddName = val
				this.$refs.modalAddHorse.show()
			},
			stopFocus(){
				document.activeElement.blur();
			},
			
			async checkFormHorse() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.tiersHorseAjout.checkForm()
                    if(result) {
                        this.$refs.modalAddHorse.hide()
						this.processing = false
                    } else {
                        this.processing = false
                    }
                }
            },

			async assignHorse(form){
				await this.SetInfoJument(form)
				await this.$refs.searchJument.onHorseId(form.horse_id)
				await this.$sync.force(true)
			},

			async load_most_used_cmep(tiers_id) {
				const most_used_cmep = await this.getMostUsedCmep(tiers_id)
                if(most_used_cmep) {
				    this.most_used_cmep = most_used_cmep
                }
			},

			loadCmep() {
				this.form.cmep = this.most_used_cmep
			}
		},
		computed: {
			date_signature() {
				return this.formatDate(this.avenant.avenant_date_signature)
			},
			canTakeResaMonte() {
				const reservable_types = ['mm']

				return this.contract
					&& this.contract.contract_num
					&& this.type_monte
					&& reservable_types.indexOf(this.type_monte.type) == -1
			},
			syndic_saillie_formatted() {
				if(_isEmpty(this.syndic_saillie)) return []

				let type_contrat = "syndicpart_is_perso"
				if(this.avenant.avenant_type_contrat == "sur_pool") {
					type_contrat = "syndicpart_is_pool"
				}

				let res = []
				this.syndic_saillie.forEach(syndic => {
					syndic.syndic_saillies.forEach(saillie => {
						if(saillie.saillie_part[type_contrat] == 1) {
							if(saillie.saillie_part.syndicpart_tiers.length === 0 || (saillie.saillie_contract !== null && saillie.saillie_contract !== this.contract_id)) {
								return
							}
							
							let tiers_tab = []
							saillie.saillie_part.syndicpart_tiers.forEach(tiers => {
								tiers_tab.push(tiers.tierpart_tier.tiers_rs)
							})

							const tab_current = {
								value: `${syndic.syndic_label} # ${saillie.saillie_number} / ${tiers_tab.join(' - ')} / ${this.$t(saillie.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)}`,
								invoice_type: saillie.saillie_part.syndicpart_invoice_type.invoicetype_code,
								saillie_id: saillie.saillie_id,
								type_contrat: type_contrat,
								syndicat: this.syndic_saillie.syndic_tiersyndicat,
								tiers: {
										tiers_id:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_id,
										tiers_rs:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_rs
									}
							}
							res.push(tab_current)

							if(saillie.saillie_contract == this.contract_id) {
								this.has_part = true
								this.saillie = tab_current
							}
						}
					})
				})

				return res
			}
		},
		watch: {
			'form.jument' (val) {
				if(val) {
					this.selectStatus()
					this.reloadNumDps()
					this.checkContract()
					this.SetInfoJument(val)
				}
			},

			'season' () {
				this.selectStatus()
				this.reloadNumDps()
				this.checkContract()
				if(this.contract.contract_num){
					this.loadSyndic()
				}
			},

			'form.tiers' (val) {
				this.reloadJument(val)
				this.load_most_used_cmep(val.tiers_id)
			},
			stallion(val) {
				if(this.contract.contract_num){
					this.loadSyndic()
				}
				this.reloadNumDps()
				this.checkContract()
			},
			canTakeResaMonte(val) {
				if(val) this.loadTiersCentreStockage()
			},
			contract_config(val) {
				if(!this.form.paillette || this.form.paillette == 0) {
					this.form.paillette = val.contractconfig_paillette
				}

				if((this.form.cmep == null || Object.keys(this.form.cmep).length == 0) && Object.keys(val.cmep).length > 0){
					this.form.cmep = val.cmep
				}
			},
			free_saillie(val) {
				if(val) {
					this.saillie = null
				}
			},
			'contract.contract_created'(val) {
				if(val == "-0001-11-30 00:00:00") {
					this.contract.contract_created = new Date()
				}
			}
		},
		components: {
			HorseAjout,
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			ModalAddAvenant : () => import('@/components/Contract/ModalAddAvenant'),
			ModalSignature : () => import('@/components/Contract/ModalSignature'),
			ChangementEtalon : () => import('@/components/Contract/AvenantType/ChangementEtalon'),
			ChangementCMEP : () => import('@/components/Contract/AvenantType/ChangementCMEP'),
			ReservationMonte : () => import('@/components/Contract/Reservation/ReservationList'),
			Saison : () => import('@/components/Contract/Type/Saison'),
			Etalon : () => import('@/components/Contract/Type/Etalon'),
			Modele : () => import('@/components/Contract/Type/Modele'),
			TypeMonte : () => import('@/components/Contract/Type/TypeMonte'),
			TableAvenantArticle : () => import('@/components/Contract/Type/TableAvenantArticle'),
			Bons : () => import('@/components/Invoice/Bons'),
			AValider : () => import('@/components/Contract/AValider'),
			ModalCourrier : () => import('@/components/Courrier/ModalContract'),
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			SearchJument : () => import('@/components/Contract/SearchJument'),
			ModalSendContract: () => import('@/components/Contract/ModalSendContract')
		}
	}
</script>
